import { createGlobalStyle } from 'styled-components';
import drawer from './drawer';
import timePicker from './timePicker';

export const GlobalStyle = createGlobalStyle`
  :root {
    ${({ $theme }) =>
      Object.keys($theme)
        ?.map?.(key => `--${key}: ${$theme[key]}`)
        .join(';')}
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--color-text-page);
    transition: color .3s;
  }

  .ant-message {
    .ant-message-notice-content {
      background-color: var(--error100);

      .anticon {
        color: var(--error700);
      }
    }
  }

  ${drawer}
  ${timePicker}
`;
