import React from 'react';
import { API } from '../../components/api';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { EntityContext } from './context';
import { usePostMessageListener } from './usePostMessageListener';

export const EntityContextProvider = ({ children }) => {
  const { sendMessage } = usePostMessageContext();
  const [entityRef, setEntityRef] = React.useState();
  const [previousEntityRef, setPreviousEntityRef] = React.useState();

  React.useEffect(() => {
    sendMessage({ type: 'cx-entity-subscribe' });
    return () => {
      sendMessage({ type: 'cx-entity-unsubscribe' });
    };
  }, [sendMessage]);

  const listener = React.useCallback(
    message => {
      if (message?.type === 'cx-entity-change') {
        setPreviousEntityRef(entityRef);
        setEntityRef(message.entityRef);
        API.setAppEntityToken(message?.token);
      }
    },
    [entityRef]
  );

  usePostMessageListener(listener);

  return <EntityContext.Provider value={{ entityRef, previousEntityRef }}>{children}</EntityContext.Provider>;
};
