import React from 'react';
import { Core } from 'connex-cds';
import { useNavigate } from 'react-router-dom';
import { Divider, Result, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useCompanySetup } from '../../../query-hooks/setup';

export const CompanySetup = () => {
  const navigate = useNavigate();
  const { isFetching, isError, data, refetch } = useCompanySetup();

  const goBack = React.useCallback(() => {
    navigate('..');
  }, [navigate]);

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Core.Button size="small" type="primary" stringId="back" data-testid="back-button" onClick={goBack} />
        <Divider orientation="left">
          <FormattedMessage id={'companySetup'} defaultMessage={'Company Setup'} />
        </Divider>
        <p>
          <FormattedMessage id={'companySetupDesc'} defaultMessage={'Description'} />
        </p>

        <Core.Button
          stringId="runSetup"
          testId="setup-company-button"
          type="primary"
          loading={isFetching}
          onClick={refetch}
        />
        {isError && (
          <Result
            status="error"
            title={<FormattedMessage id={'error'} defaultMessage={'Error'} />}
            subTitle={<FormattedMessage id={'compSetupErrorMessage'} defaultMessage={'Error has occurred'} />}
          />
        )}

        {data && data.success && (
          <Result
            status="success"
            title={<FormattedMessage id={'success'} defaultMessage={'Success'} />}
            subTitle={<FormattedMessage id={'compSetupSuccessMessage'} defaultMessage={'Company Setup Is Complete'} />}
          />
        )}
      </Space>
    </>
  );
};
