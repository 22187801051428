import React from 'react';
import { Outlet } from 'react-router-dom';
import { useInitializeMenu } from '../../../util/useMenu';

export const Authenticated = ({ menuConfig }) => {

  useInitializeMenu(menuConfig);

  return <Outlet />;
};
