import cn from 'classnames';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useFormContext } from '../context-provider/FormProvider';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const DefaultEditor = ({ updateQuery, createQuery, deleteQuery, onFinally }) => {
  const { Components, values, mode, ...formContext } = useFormContext();

  const { SaveButton, CancelButton, DeleteButton } = Components;

  const handleCreate = React.useCallback(
    connection =>
      createQuery?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createQuery, onFinally]
  );

  const handleUpdate = React.useCallback(
    connection =>
      updateQuery?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [onFinally, updateQuery]
  );

  const handleDelete = React.useCallback(
    connectionRef =>
      deleteQuery?.(connectionRef)?.then(response => {
        onFinally?.();
        return response;
      }),
    [deleteQuery, onFinally]
  );

  return (
    <Styled className={cn('service-editor')}>
      {formContext?.config?.fields?.map?.(field => {
        const componentName = pascalCase(field.name || field.path);
        const Component = Components[componentName];
        return Component ? <Component /> : null;
      })}
      <div className="buttons">
        <div className="left">
          <DeleteButton onDelete={handleDelete} />
        </div>
        <div className="right">
          <CancelButton onCancel={onFinally} enabled />
          <SaveButton onCreate={handleCreate} onUpdate={handleUpdate} />
        </div>
      </div>
    </Styled>
  );
};
