import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Setup } from '../api';

export const useCompanySetup = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef],
    queryFn: () => Setup.setupCompany({ entityRef }),
    staleTime: Infinity,
    enabled: false,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};
