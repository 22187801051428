import React from 'react';
import { API } from './components/api';
import { getApiConfig } from './components/http/http';
import { usePostMessageContext } from './post-message/PostMessage';

export const useAppProductionWarning = () => {
  const apiConfig = getApiConfig(API.getSource());

  const { isProductionApp, isLocalhostApp, productionOverrides } = React.useMemo(() => {
    const productionRegions = ['eu', 'us'];
    const { hostname } = window.location;

    const apiHostnamePrefix = 'REACT_APP_ENDPOINT_';

    const parseRegion = hostname => {
      const split = hostname.split('.');
      return split[0].startsWith('localhost') ? 'localhost' : split[1];
    };

    const isProductionUrl = url => {
      const protocolSplit = url.split('//');
      const region = parseRegion(protocolSplit[1]);
      return productionRegions.includes(region);
    };

    const apiHostnameOverrides = Object.keys(process.env)
      .filter(key => key.startsWith(apiHostnamePrefix))
      .map(key => ({
        service: key.replace(apiHostnamePrefix, ''),
        url: process.env[key],
      }))
      .map(override => {
        return {
          ...override,
          isProduction: isProductionUrl(override.url),
        };
      });

    const isLocalhostApp = hostname === 'localhost';
    const isProductionApp = isProductionUrl(window.location.href);

    return { isProductionApp, isLocalhostApp, productionOverrides: apiHostnameOverrides?.some?.(o => o.isProduction) };
  }, []);

  const { sendMessage } = usePostMessageContext();

  sendMessage({ type: 'api-info', service: apiConfig.id, isProductionApp, isLocalhostApp, productionOverrides });
};
