import React from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { cacheItem, getCachedItem } from '../../util/cache';
import { usePostMessageHandler } from '../../util/usePostMessageHandler';
import { API } from '../api';
import { UserContext } from './UserContext';

export const USER_KEY = '73804c78-c9d6-4673-8ad0-b96d1ef08ff2';

export const UserProvider = ({ children }) => {
  const cachedUser = getCachedItem(USER_KEY);
  const { sendMessage } = usePostMessageContext();

  const [user, _setUser] = React.useState(cachedUser ? JSON.parse(cachedUser) : null);
  const [role, setRole] = React.useState();

  React.useEffect(() => {
    sendMessage({ type: 'user-rq' });
  }, [sendMessage]);

  const handleMessageEvent = React.useCallback(event => {
    const message = event?.data;
    if (message?.user) {
      _setUser(message?.user);
      setRole(message.role);
      API.setLoginToken(message?.user?.access_token);
    }
  }, []);

  usePostMessageHandler(handleMessageEvent);

  const hasPermission = React.useCallback(
    permission => {
      return role?.permissions?.[permission] || false;
    },
    [role?.permissions]
  );

  const permissions = React.useMemo(() => {
    return Object.keys(role?.permissions || {}).filter(key => !!role?.permissions?.[key]);
  }, [role?.permissions]);

  return (
    <UserContext.Provider value={{ user, role, setRole, hasPermission, permissions }}>{children}</UserContext.Provider>
  );
};
