import { isEmpty } from 'lodash';
import React from 'react';
import { Translate } from '../Translate';

export const Uom = ({ uom, short, testId }) => {
  if (isEmpty(uom) || !uom?.value || !uom?.uomCode) return '';

  return (
    <Translate
      stringId={`${uom?.uomCode}${short ? '_short' : ''}`}
      values={{ value: uom?.value }}
      data-testid={testId}
    />
  );
};
