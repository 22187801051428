import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { getSource } from '../../api';
import { Button } from '../../core';
import { DrawerProvider } from '../../drawer';
import { Column } from '../../layout';
import { Translate } from '../../localization';
import { H3 } from '../../typography';
import { useFormContext } from '../context-provider/FormProvider';
import { useListAndDetailContext } from './ListAndDetailProvider';
import { useClickHandlers } from './useClickHandlers';

const Styled = styled(Column)`
  .header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .left {
      display: flex;
      //gap: 15px;
      align-items: center;
      flex: 1;

      h3 {
        margin-bottom: 0;
      }
    }
    .right {
      display: flex;
      button {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export const List = () => {
  const { config, pickerMode, data, showHeader = true, customActions } = useListAndDetailContext();

  const { handleRowClick, handleAddClick } = useClickHandlers();

  const { Components } = useFormContext();

  const actions = React.useMemo(() => {
    const newActions =
      customActions?.map?.(action => <Button size="small" type="primary" {...action} className="action-button" />) ||
      [];

    if (handleAddClick) {
      newActions.push(
        <Components.AddButton onClick={handleAddClick} size="small" stringId={`add-${config.name || config.path}`} />
      );
    }
    return newActions;
  }, [config.name, config.path, customActions, handleAddClick]);

  return (
    <Styled className={cn('service-list')}>
      <div className="header">
        <div className="left">
          {showHeader ? (
            <H3>
              <Translate
                stringId={`${config.name || config.path}ListHeader`}
                data-testid={`${config.name || config.path}-heading`}
              />
            </H3>
          ) : null}
        </div>
        <div className="right">{!pickerMode && <DrawerProvider>{actions}</DrawerProvider>}</div>
      </div>
      <Column className="list-section">
        <Components.ListComponent
          onClick={handleRowClick}
          gridName={`${getSource()}-${config.name || config.path}-grid`}
          queryData={data}
        />
      </Column>
    </Styled>
  );
};
