import React from 'react';
import { usePostMessageListener } from '../../util/entity-provider/usePostMessageListener';

const prefixMessageKeys = (messages, appId = 'app') => {
  return Object.keys(messages).reduce((acc, messageKey) => {
    acc[`${appId}__${messageKey}`] = messages[messageKey];
    return acc;
  }, {});
};

export const useMergeMessages = messages => {
  const [appMessages, setAppMessages] = React.useState();
  const [appId, setAppId] = React.useState();
  const [mergedMessages, setMergedMessages] = React.useState(messages);

  React.useEffect(() => {
    if (appMessages && appId) {
      const merged = Object.keys(appMessages).reduce(
        (acc, language) => {
          const prefixed = prefixMessageKeys(appMessages[language], appId);
          acc[language] = { ...(acc[language] || {}), ...(prefixed || {}) };
          return acc;
        },
        { ...messages }
      );
      setMergedMessages(merged);
    }
  }, [appMessages, appId, messages]);

  const listener = React.useCallback(message => {
    if (message?.type === 'messages') {
      console.log('Received Messages!', message);
      setAppMessages(message.messages);
      setAppId(message.appId);
    }
  }, []);

  usePostMessageListener(listener);

  return mergedMessages;
};
