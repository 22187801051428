import React, { useContext } from 'react';

const PostMessageContext = React.createContext();

export const usePostMessageContext = () => {
  const context = useContext(PostMessageContext);
  if (!context) {
    throw new Error(`usePostMessageContext cannot be rendered outside of the PostMessageContext context provider`);
  }
  return context;
};

const ConnexPostMessageToIframe = ({ children }) => {
  const sendMessage = React.useCallback(message => {
    console.log('connex sending message', message);
    const iframe = document.getElementById('service-iframe')?.contentWindow;

    if (iframe) {
      //TODO: specify targetOrigin dynamically
      iframe.postMessage(message, '*');
    }
  }, []);

  return <PostMessageContext.Provider value={{ sendMessage }}>{children}</PostMessageContext.Provider>;
};

const IframePostMessage = ({ children }) => {
  const handleMessageEvent = React.useCallback(event => {}, []);

  const sendMessage = React.useCallback(message => {
    console.log('iframe sending message', message);
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(message, '*');
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
  }, [handleMessageEvent]);

  return <PostMessageContext.Provider value={{ sendMessage }}>{children}</PostMessageContext.Provider>;
};

export const PostMessage = ({ children, isIframe, sendMessages = false }) => {
  return isIframe ? (
    <IframePostMessage>{children}</IframePostMessage>
  ) : (
    <ConnexPostMessageToIframe>{children}</ConnexPostMessageToIframe>
  );
};
