import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { DropdownMenu, PLACEMENT, TRIGGER } from '../../core/dropdown-menu/DropdownMenu';
import style from '../../theme/theme-switcher/style';
import { useLocalizationContext } from '../LocalizationProvider';
import { Translate } from '../Translate';

const Styled = styled.div`
  ${style}
`;

export const LocaleSwitcher = ({ className, persist = true }) => {
  const { userLocale, setUserLocale, supportedLanguages } = useLocalizationContext();

  const handleChange = React.useCallback(
    value => {
      setUserLocale(value, persist);
    },
    [persist, setUserLocale]
  );

  return (
    <Styled className={cn('locale-switcher', className)}>
      <DropdownMenu
        trigger={[TRIGGER.CLICK]}
        placement={PLACEMENT.TOP_LEFT}
        menuItems={supportedLanguages.map(language => ({ id: language, stringId: `languages.${language}` }))}
        onClick={handleChange}
        activeId={userLocale}
      >
        <div className="dropdown-content">
          <div className="icon">
            <i className="fa-solid fa-globe" />
          </div>
          <div className="label">
            <Translate stringId={`languages.${userLocale}`} data-testid="locale-switcher" />
          </div>
        </div>
      </DropdownMenu>
    </Styled>
  );
};
