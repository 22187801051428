import cn from 'classnames';
import styled from 'styled-components';
import React from 'react';
import { useLocalizationContext } from '../../localization';
import { parse } from './parse';
import style from './style';

function sanitize(str, decimalSeparator) {
  if (!str) return '';

  const regex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');

  // Ensure only numbers and decimal separator
  const sanitized = str.replace(regex, '');

  // Remove all but first decimal separator
  return sanitized.split(decimalSeparator).reduce((prev, curr, i) => prev + (i === 1 ? decimalSeparator : '') + curr);
}

const Styled = styled.span`
  ${style}
`;

export const NumberInput = ({
  addOnAfter,
  value,
  onFocus,
  onBlur,
  onChange,
  testId,
  minValue,
  maxValue,
  addonAfter,
  ...props
}) => {
  const [_value, setValue] = React.useState(value || '');
  const [formattedValue, setFormattedValue] = React.useState('');
  const { userLocale } = useLocalizationContext();

  const decimalSeparator = React.useMemo(() => {
    const number = 1.1;
    const separator = Intl.NumberFormat(userLocale)
      .formatToParts(number)
      .find(part => part.type === 'decimal').value;

    return separator;
  }, [userLocale]);

  React.useEffect(() => {
    const formatted = Intl.NumberFormat(userLocale).format(value);

    if (formatted === 'NaN') {
      setValue('', decimalSeparator);
      setFormattedValue('');
      return;
    }

    setValue(sanitize(formatted, decimalSeparator));
    setFormattedValue(formatted);
  }, [decimalSeparator, userLocale, value]);

  const handleChange = React.useCallback(
    e => {
      const strValue = e.target.value;
      const sanitizedValue = sanitize(strValue, decimalSeparator);
      setValue(sanitizedValue);

      const lastCharacter = strValue?.slice?.(sanitizedValue.length - 1);
      if (lastCharacter !== decimalSeparator) {
        onChange?.(parse(userLocale, sanitizedValue));
      }
    },
    [decimalSeparator, onChange, userLocale]
  );

  const [mode, setMode] = React.useState('read');

  const handleFocus = React.useCallback(
    e => {
      setMode('edit');
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = React.useCallback(
    e => {
      setMode('read');
      onChange?.(parse(userLocale, _value));
      setTimeout(() => {
        onBlur?.(e);
      }, 100);
    },
    [_value, onBlur, onChange, userLocale]
  );

  const displayValue = React.useMemo(() => {
    if (mode === 'edit') {
      return _value;
    }

    return formattedValue;
  }, [_value, formattedValue, mode]);

  return (
    <Styled className={cn('number-input-wrapper', { addon: !!addonAfter })}>
      <input
        className={cn('number-input')}
        lang={userLocale}
        onChange={handleChange}
        value={displayValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {addonAfter ? <span className="number-input-addon">{addonAfter}</span> : null}
    </Styled>
  );
};
