import { css } from 'styled-components';

export default css`
  width: 100%;
  padding: 0;

  .layout-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;

    > .webhooks-card {
      margin: 10px auto;
      text-align: center;
      width: 300px;
      cursor: default;
    }

    .item-type-id {
      font-size: 1.3em;
      font-weight: 600;
    }

    .active-status {
      font-size: 3em;
      color: #04B431;
    }

    .inactive-status {
      font-size: 3em;
      color: #DF0101;
    }
  }
`;
