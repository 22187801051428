import { find } from 'lodash';
import React from 'react';
import { FormattedTime } from 'react-intl';
import timeZones from '../../core/time-zone-picker/zone/zones';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';
import { TimeZone } from './Timezone';

export const Time = ({ date, hideTimeZone = false, testId }) => {
  const { timeZone } = useLocalizationContext();

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  };

  const dateTime = React.useMemo(() => toDateTime(date, timeZone), [date, timeZone]);

  if (!date) return null;

  return (
    <span data-testid={testId}>
      <FormattedTime value={dateTime.toJSDate()} {...options} />
      {hideTimeZone ? (
        ''
      ) : (
        <>
          {' '}
          (<TimeZone date={dateTime} />)
        </>
      )}
    </span>
  );
};
