import { http } from 'connex-cds';

export default {
  listUsers: ({ entityRef }) => http.get({ apiName: 'cxp', path: `/admin/${entityRef}/users` }),
  inviteUser: ({ entityRef, user }) =>
    http.post({ apiName: 'cxp', path: `/admin/${entityRef}/users/invite`, data: user }),
  getInvitedUsers: entityRef => http.get({ apiName: 'authnz', path: `/${entityRef}/user-invites/cxp` }),
  getUserRole: profileRef => http.get({ apiName: 'authnz', path: `/profiles/${profileRef}/roles` }),
  getUserRbac: ({ entityRef, profileRef }) =>
    http.get({ apiName: 'cxp', path: `/admin/${entityRef}/users/${profileRef}/rbac` }),
  getUserSubscriptions: ({ entityRef, profileRef }) =>
    http.get({ apiName: 'cxp', path: `/${entityRef}/profile/${profileRef}/subscriptions` }),
  deleteOneUserSubscriptions: ({ subscriptionRef, entityRef, profileRef }) =>
    http.delete({
      apiName: 'cxp',
      path: `/${entityRef}/profile/${profileRef}/subscriptions/${subscriptionRef}`,
    }),
  deleteAllUserSubscriptions: ({ entityRef, profileRef }) =>
    http.delete({ apiName: 'cxp', path: `/${entityRef}/profile/${profileRef}/subscriptions` }),
};
