import { Core, Drawer, Form } from 'connex-cds';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useListRoles } from '../../../query-hooks/roles';
import { useInviteUser } from '../../../query-hooks/users';
import { find, orderBy, reject } from 'lodash';
import { Col, Divider, Row, Table } from 'antd';
import { useListCustomers } from '../../../query-hooks/customers';
import { getCustomerColumns, getProjectColumns } from '../columns/columns';
import { useParams } from 'react-router-dom';
import { filterProjectList } from '../util/filterProjects';
import { buildCustomerRBAC } from '../util/buildCustomerRBAC';
import { addProjectsToCustomer } from '../util/addProjectsToCustomer';
import { addOptionLabels } from '../util/addOptionLabels';

export const View = () => {
  const { Components, values } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const [allRoles, setAllRoles] = React.useState([]);
  const [busy, setBusy] = React.useState(false);
  const [selectedCustomersInvite, setSelectedCustomersInvite] = React.useState([]);
  const [projectsList, setProjectsList] = React.useState([]);
  const [projectsLoading, setProjectsLoading] = React.useState(false);
  const [showCustomers, setShowCustomers] = React.useState(false);
  const { entityRef } = useParams();
  const [isValidForm, setIsValidForm] = useState(false);

  const customerQuery = useListCustomers();
  const inviteUser = useInviteUser();
  const roleQuery = useListRoles();

  // Show Hide The Customers Based On Current Role Value
  React.useEffect(() => {
    if (
      !values?.role?.name ||
      values?.role?.name === 'company-admin' ||
      values?.role?.name === 'internal-user-read-only'
    ) {
      setShowCustomers(false);
    } else {
      setShowCustomers(true);
    }
  }, [values?.role]);

  useEffect(() => {
    if (values?.username && values?.username?.length > 8 && values?.role?.name) {
      setIsValidForm(true);
    }
  }, [values?.role, values?.username]);

  // Update project list when customer is changed
  React.useEffect(() => {
    // Call Projects API and update the projects list
    (async () => {
      if (values?.customer?.id) {
        setProjectsLoading(true);
        const filteredProjects = await filterProjectList(entityRef, values?.customer?.id);
        setProjectsLoading(false);
        setProjectsList(filteredProjects);
      }
    })();

    return () => {};
  }, [values?.customer, entityRef]);

  const handleSelectCustomer = React.useCallback(async () => {
    if (!find(selectedCustomersInvite, { id: values?.customer?.id })) {
      setSelectedCustomersInvite(s => [...s, { ...values?.customer, projects: [] }]);
    }
  }, [selectedCustomersInvite, values]);

  const removeCustomer = React.useCallback(
    async customerId => {
      const customersArray = reject(selectedCustomersInvite, { id: customerId });
      setSelectedCustomersInvite(customersArray);
    },
    [selectedCustomersInvite]
  );

  const handleClick = React.useCallback(async () => {
    setBusy(true);
    const customers = buildCustomerRBAC(selectedCustomersInvite);

    const response = await inviteUser({
      username: values?.username?.trim()?.toLowerCase(),
      roleRef: values?.role?.roleRef || values?.role?.crn,
      customers,
    });

    setBusy(false);

    if (response?.success === 'ok') {
      closeDrawer();
    }
  }, [selectedCustomersInvite, values]);

  // Add the projects to the selectedCustomers array
  const handleAddProject = React.useCallback(
    ({ projectCrn, projectId, type }) => {
      const customersArray = addProjectsToCustomer(
        customerQuery?.data,
        values?.customer?.id,
        projectCrn,
        projectId,
        type,
        selectedCustomersInvite,
        projectsList
      );

      setSelectedCustomersInvite(customersArray);
    },
    [projectsList, selectedCustomersInvite, values]
  );

  // Add labels to the roles if they don't have them
  React.useEffect(() => {
    if (roleQuery?.data) {
      setAllRoles(addOptionLabels(roleQuery?.data));
    }
  }, [roleQuery.data]);

  const customerColumns = getCustomerColumns(removeCustomer);
  const projectColumns = getProjectColumns(handleAddProject);

  return (
    <div className={cn('view')}>
      <Components.Username />
      <Components.Role options={allRoles} />

      {showCustomers && (
        <>
          <Row gutter={12} style={{ marginTop: 24 }}>
            <Col span={6}>
              <Components.Customer
                options={orderBy(customerQuery.data, ['name'], ['asc'])}
                busy={customerQuery.isLoading}
              />
            </Col>
            <Col span={6} style={{ marginTop: 30 }}>
              <Core.Button onClick={handleSelectCustomer} testId="add-customer" stringId="addCustomer" type="primary" />
            </Col>
          </Row>
          {selectedCustomersInvite && (
            <>
              <Divider orientation="left">Customers</Divider>
              <Table
                columns={customerColumns}
                dataSource={selectedCustomersInvite}
                rowKey={'customerRef'}
                style={{ margin: '14px 0' }}
              />
            </>
          )}
          {values?.customer && (
            <>
              <Divider orientation="left">Projects for {values?.customer?.name}</Divider>
              <Table
                columns={projectColumns}
                dataSource={projectsList}
                loading={projectsLoading}
                style={{ margin: '14px 0' }}
              />
            </>
          )}
        </>
      )}
      <div className="actions">
        <Core.Button
          onClick={handleClick}
          testId="invite-button"
          stringId="inviteUser"
          disabled={!isValidForm}
          type="primary"
          loading={busy}
        >
          Invite
        </Core.Button>
      </div>
    </div>
  );
};
