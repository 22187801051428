import React from 'react';
import { AppContext } from './context';

const AppContextProvider = ({ children }) => {
  const [appRef, setAppRef] = React.useState(null);
  const [appEntities, setAppEntities] = React.useState();
  const [appConfig, setAppConfig] = React.useState();
  const [iframeLoading, setIframeLoading] = React.useState(false);
  const [currentFeature, setCurrentFeature] = React.useState();

  React.useEffect(() => {
    setCurrentFeature(undefined);
  }, [appRef]);

  const isEntityPickerRequired = React.useMemo(() => {
    return appRef && appConfig?.isEntitySpecific;
  }, [appConfig, appRef]);

  return (
    <AppContext.Provider
      value={{
        appRef,
        setAppRef,
        appConfig,
        setAppConfig,
        appEntities,
        setAppEntities,
        iframeLoading,
        setIframeLoading,
        isEntityPickerRequired,
        currentFeature,
        setCurrentFeature,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
