import { isEmpty } from 'lodash';
import React from 'react';
import { Button } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { DEFAULT_MODE, MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';

export const SaveButton = ({ onCreate, onUpdate, onDone, onFinally, suppressDisabledStyling }) => {
  const arrayContext = useArrayContext();
  const { showErrors, resetForm, dirty, isValid, values, status } = useFormContext();

  const [busy, setBusy] = React.useState(false);

  const {
    fieldConfig: { keyPath },
    onChange,
    value: existingValues,
    setMode,
    mode,
  } = arrayContext;

  const pushToParentForm = React.useCallback(
    values => {
      let newValues;

      if (mode === MODES.EDIT) {
        newValues = existingValues.map(existing => (values?.[keyPath] === existing[keyPath] ? values : existing));
      } else if (mode === MODES.CREATE) {
        newValues = [...existingValues, values];
      }

      onChange(newValues);

      setMode?.(DEFAULT_MODE);
    },
    [existingValues, keyPath, mode, onChange, setMode]
  );

  const handleSave = React.useCallback(() => {
    let saveFn;
    if (mode === MODES.CREATE) {
      saveFn = onCreate;
    } else if (mode === MODES.EDIT) {
      saveFn = onUpdate;
    }

    saveFn = saveFn || onDone;

    if (saveFn) {
      const response = saveFn(values);

      if (response instanceof Promise) {
        setBusy(true);
        response
          .then(r => {
            pushToParentForm(r);
            resetForm({ reinitialize: true });
            arrayContext.setActiveRowId(null);
            return r;
          })
          .finally(() => {
            setBusy(false);
            onFinally?.();
          });
      } else {
        resetForm({ reinitialize: true });
        arrayContext.setActiveRowId(null);
        pushToParentForm(response || values);
        onFinally?.();
      }
      // Have to return here in case the response was a promise.
      return;
    }

    // The default behavior is just to reset things and inform the parent
    resetForm({ reinitialize: true });
    arrayContext.setActiveRowId(null);
    pushToParentForm(values);
  }, [mode, onDone, resetForm, arrayContext, pushToParentForm, values, onCreate, onUpdate, onFinally]);

  const disabled = React.useMemo(() => {
    if (suppressDisabledStyling) return false;

    return !dirty || !isValid || !mode || !isEmpty(status);
  }, [dirty, isValid, mode, status, suppressDisabledStyling]);

  const handleClick = React.useCallback(() => {
    if (isValid) {
      handleSave();
    } else {
      showErrors?.();
    }
  }, [handleSave, isValid, showErrors]);

  return (
    <Button
      onClick={handleClick}
      type="primary"
      stringId={mode === MODES.CREATE ? 'add' : 'update'}
      disabled={disabled}
      data-testid="save-button"
      loading={busy}
      suppressDisabledStyling={suppressDisabledStyling}
    />
  );
};
