import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Column } from '../../layout';
import { useListAndDetailContext } from './ListAndDetailProvider';

const Styled = styled(Column)``;

export const Detail = ({ onFinally }) => {
  const { Editor, updateQuery, createQuery, deleteQuery, ...context } = useListAndDetailContext();

  if (!updateQuery || !createQuery) {
    console.error('updateQuery and createQuery are required.');
  }

  return (
    <Styled className={cn('detail')}>
      <Editor
        updateQuery={updateQuery}
        createQuery={createQuery}
        deleteQuery={deleteQuery}
        onFinally={onFinally}
        context={context}
      />
    </Styled>
  );
};
