import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Users } from '../api';

export const useListUsers = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['users', entityRef],
    queryFn: () => Users.listUsers({ entityRef }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useListInvitedUsers = () => {
  const { entityRef } = useParams();

  const queryKey = ['invitedUsers', entityRef];

  const query = useQuery({
    queryKey,
    queryFn: () => Users.getInvitedUsers(entityRef),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useUpdateUser = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['updateUsers', entityRef];

  const mutation = useMutation({
    mutationFn: profile => Users.updateUser({ entityRef, profile }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['users'] });
        queryClient.invalidateQueries({ queryKey: ['invitedUsers'] });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};

export const getUserRole = profileRef => {
  const { entityRef } = useParams();

  const queryKey = ['userRoles', entityRef, profileRef];

  const query = useQuery({
    queryKey,
    queryFn: () => Users.getUserRole(profileRef),
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const getUserRbac = profileRef => {
  const { entityRef } = useParams();

  const queryKey = ['userRbac', entityRef, profileRef];

  const query = useQuery({
    queryKey,
    queryFn: () => Users.getUserRbac({ entityRef, profileRef }),
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useInviteUser = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['invitedUsers', entityRef];

  const mutation = useMutation({
    mutationFn: user =>
      Users.inviteUser({
        entityRef,
        user,
      }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      // const newData = previousItem?.map?.(item => (item.username === newItem.username ? newItem : item));
      // queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    // onError: (err, newItem, context) => {
    //   queryClient.setQueryData(queryKey, context.previousItem);
    // },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

/**
 * Functions query for get the subscription for a user
 * @returns A collection of user subscription
 * @author Juan David Reina
 */
export const useGetUserSubscriptions = (profileRef, entityRef) => {
  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['subscriptions', entityRef, profileRef],
    queryFn: () => Users.getUserSubscriptions({ entityRef, profileRef }),
    retry: 0,
  });

  return query;
};

/**
 * Function for delete the subscriptions of the user
 * @returns true or error
 * @author Juan David Reina
 */
export const useDeleteUserSubscription = (crn, profileRef, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['users', crn, profileRef, entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      if (!data?.subscriptionRef) {
        Users.deleteAllUserSubscriptions({ ...data });
      } else {
        Users.deleteOneUserSubscriptions({ ...data });
      }
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};
