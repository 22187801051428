import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useLocalizationContext } from '../../../localization';
import { ModalProvider, useModalContext } from '../../../modal';
import { useFormContext } from '../../context-provider/FormProvider';
import { Keypad } from './Keypad';
import { parse } from './parse';
import style from './style';

const Styled = styled.span`
  ${style}
`;

const NumberInputView = ({
  value,
  onFocus,
  onBlur,
  onChange,
  testId,
  minValue,
  maxValue,
  addonAfter,
  labelStringId,
  ...props
}) => {
  const { openModal } = useModalContext();
  const { userLocale } = useLocalizationContext();
  const { setTouched, touched } = useFormContext();

  const handleChange = React.useCallback(
    newValue => {
      setTouched({ ...touched, [props.name]: true });
      onChange(newValue);
    },
    [onChange, props.name, setTouched, touched]
  );

  const handleClick = React.useCallback(() => {
    openModal({
      titleStringId: labelStringId,
      component: <Keypad value={value} onChange={handleChange} addonAfter={addonAfter} />,
    });
  }, [openModal, labelStringId, value, handleChange, addonAfter]);

  const formattedValue = React.useMemo(() => {
    const formatted = Intl.NumberFormat(userLocale).format(value);
    const parsed = parse(userLocale, formatted);
    const valid = Number(parsed);

    return valid ? formatted : '';
  }, [userLocale, value]);

  return (
    <Styled className={cn('number-input-wrapper', { addon: !!addonAfter })}>
      <div className="number-display" onClick={handleClick}>
        {formattedValue}
      </div>
      {addonAfter ? <span className="number-input-addon">{addonAfter}</span> : null}
    </Styled>
  );
};

export const NumberInput = props => (
  <ModalProvider>
    <NumberInputView {...props} />
  </ModalProvider>
);
