import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import pkg from '../package.json';
import packageJson from '../package.json';
import { AuthProvider } from './components/auth';
import { DrawerProvider } from './components/drawer';
import { Column } from './components/layout';
import { Localization } from './components/localization';
import { ModalProvider } from './components/modal';
import { ThemeProvider } from './components/theme';
import { UserProvider } from './components/user';
import { ConnexUserProvider } from './components/user/ConnexUserProvider';
import { PostMessage } from './post-message/PostMessage';
import { AppDataProvider } from './util/app-data/AppData';
import AppContextProvider from './util/app-provider/AppContext';
import { getCachedItem } from './util/cache';
import { AppEntityContextProvider } from './util/entity-provider/AppEntityProvider';

const Styled = styled(Column)`
  transition: all 0.3s;
  background-color: var(--color-background-page);
  color: var(--color-text-page);
  font-family: 'Work Sans', serif;
  height: 100vh;
  width: 100%;
  gap: 0;
`;

console.log(`SDK Version: ${packageJson.version}`);

export const AppConnexDesignSystem = ({
  themes,
  onLogin,
  toLogin,
  children,
  className,
  messages,
  initialLocale,
  initialTimeZone,
  appId = 'connex',
  ...props
}) => {
  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`, className)} {...props}>
      <AppDataProvider {...props}>
        <PostMessage>
          <Localization
            messages={messages}
            initialLocale={initialLocale || getCachedItem('language')}
            initialTimeZone={initialTimeZone || getCachedItem('timeZone')}
            appId={appId}
          >
            <ThemeProvider themes={themes}>
              <ConnexUserProvider>
                <AuthProvider onLogin={onLogin} toLogin={toLogin}>
                  <ModalProvider>
                    <DrawerProvider>
                      <AppContextProvider>
                        <AppEntityContextProvider>{children}</AppEntityContextProvider>
                      </AppContextProvider>
                    </DrawerProvider>
                  </ModalProvider>
                </AuthProvider>
              </ConnexUserProvider>
            </ThemeProvider>
          </Localization>
        </PostMessage>
      </AppDataProvider>
    </Styled>
  );
};
