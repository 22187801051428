import common from '../common';

export default {
  ...common,

  users: 'Users',
  inviteUser: 'Invite User',
  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,
  family_name: 'Last Name',
  username: 'Username',
  given_name: 'First Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  role: 'Role',
  emailOrPhoneNumber: 'Email or Phone Number',
  usersEditor: 'User Editor',
  invitedUsers: 'Invited Users',
  columnFilters: 'Column Filters',
  'cxp-admin__users': 'Users',
  inviteDate: 'Invite Date',
  updateRole: 'Update Role',
  setup: 'Setup',
  addresses: 'Addresses',
  projects: 'Projects',
  customers: 'Customers',
  customer: 'Customer',
  project: 'Project',
  addCustomer: 'Add Customer',
  hidden: 'Hidden',
  visible: 'Visible',
  resetColumns: 'Reset Columns',
  undoChanges: 'Undo Changes',
  save: 'Save',
  hideAll: 'Hide All',
  showAll: 'Show All',
  gridPreferences: 'Grid Preferences',
  addProject: 'Add Project',
  addProjectByID: 'All Projects With This ID',
  addProjectByRef: 'This Project',
  back: 'Back',
  // Company Setup block
  runSetup: 'Run Setup',
  companySetup: 'Company Setup',
  companySetupDesc: 'Initialize the company to create roles and enable orders / tickets.',
  compSetupErrorTitle: 'Something went wrong!',
  compSetupErrorMessage: 'Contact admin for more details.',
  error: 'Error',
  success: 'Success',
  compSetupSuccessMessage: 'Company Setup is complete!',
  // Webhooks block
  webhooks: 'Webhooks',
  webhooksTitle: 'Available Webhooks',
  webhooksDesc: 'A series of useful webhooks for the exclusive use of users under the Command Alkon domain',
  itemTypeIdTicket: 'Ticket',
  itemTypeIdTicketEvent: 'Ticket-Event',
  itemTypeIdOrder: 'Order',
  webhookData: 'Webhook Data',

  delete: 'Delete',
  name: 'Name',
  id: 'ID',
  address: 'Address',
  projectsFor: 'Projects for',
  projectsById: 'Projects By Id',
  // User Subscription block
  specificProjects: 'Specific Projects',
  userSubscriptions: 'View Subscriptions',
  notificationType: 'Notification Type',
  projectId: 'Project Id',
  subscriptionType: 'Subscription Type',
  customerId: 'Customer Id',
  deleteOneSubscriptionPopupTitle:
    'This will delete the selected subscription for the user. Are you sure you want to delete this subscription?',
  deleteAllSubscriptionPopupTitle:
    'This will delete all subscriptions for the user. Are you sure you want to delete all subscriptions?',
  deletePopupYes: 'Yes',
  deletePopupNo: 'No',
  notificationTypeAllLoads: 'All Trucks Enroute',
  notificationTypeFirstLoad: 'First Truck Enroute',
  subscriptionsForUser: 'Subscriptions For {firstName} {lastName}',
  subscriptionModalDeleteAllBtn: 'DELETE ALL',
  subscriptionModalCloseBtn: 'CLOSE',

  all: 'ALL',
  required: 'Required',
};
